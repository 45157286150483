import { PATH_CUSTOMER, PATH_CUSTOMER_REGISTER, PATH_LOGISTIC } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardCustomerLayout from 'src/layouts/DashboardCustomerLayout';

// ----------------------------------------------------------------------

const AppCustomerRoutes = {
  path: PATH_CUSTOMER.root,
  guard: AuthProtect,
  layout: DashboardCustomerLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // DASHBOARD
    {
      exact: true,
      path: PATH_CUSTOMER.general.dashboard,
      component: lazy(() => import('src/views/CustomerDashboardGroup/dashboard/DashboardAppView'))
    },

    // NOTIFICATIONS
    {
      exact: true,
      path: PATH_CUSTOMER.general.notifications,
      component: lazy(() => import('src/views/CustomerDashboardGroup/notifications/Notifications'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.notificationDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/notifications/detail/NotificationView'))
    },

    // OFFERS
    {
      exact: true, 
      path: PATH_CUSTOMER.general.offers,
      component: lazy(() => import('src/views/CustomerDashboardGroup/offers/Offers'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.offersDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/offers/offer-detail/OfferView2'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.offersDetail.productDetail,
      component: lazy(() => import('src/views/CustomerDashboardGroup/offers/offer-detail/ProductDetail'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.offersDetail.offerBasket,
      component: lazy(() => import('src/views/CustomerDashboardGroup/offers/offer-detail/OfferBasket'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.offersDetail.offerSuccess,
      component: lazy(() => import('src/views/CustomerDashboardGroup/offers/offer-detail/OfferSuccess'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.offersDetail.manageRating,
      component: lazy(() => import('src/views/CustomerDashboardGroup/offers/offer-detail/ManageRating'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.offersDetail.manageRecommendedCartonQty,
      component: lazy(() => import('src/views/CustomerDashboardGroup/offers/offer-detail/ManageRecommendedCartonQty'))
    },

    // PRE ORDER
    {
      exact: true,
      path: PATH_CUSTOMER.general.preOrder,
      component: lazy(() => import('src/views/CustomerDashboardGroup/pre-order/PreOrder'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.preOrderDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/pre-order/PreOrderView'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.preOrderDetail.viewOrderDetail,
      component: lazy(() => import('src/views/CustomerDashboardGroup/pre-order/ViewOrderDetail'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.preOrderDetail.staffPreOrderBreakdown,
      component: lazy(() => import('src/views/CustomerDashboardGroup/pre-order/StaffPreOrderBreakdown'))
    },

    // CLOSED OFFERS
    {
      exact: true,
      path: PATH_CUSTOMER.general.closedOffers,
      component: lazy(() => import('src/views/CustomerDashboardGroup/closed-offers/ClosedOffers'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.closedOffersDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/closed-offers/closed-offers-detail/ClosedOfferView'))
    },

    // ALL ORDER
    {
      exact: true, 
      path: PATH_CUSTOMER.general.orderAll,
      component: lazy(() => import('src/views/CustomerDashboardGroup/order-all/OrderAll'))
    },
    // {
    //   exact: true,
    //   path: PATH_CUSTOMER.general.orderAllDetail.view,
    //   component: lazy(() => import('src/views/CustomerDashboardGroup/invoice/detail/InvoiceView2'))
    // },

    // ORDER
    {
      exact: true, 
      path: PATH_CUSTOMER.general.order,
      component: lazy(() => import('src/views/CustomerDashboardGroup/invoice/Invoice'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.orderDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/invoice/detail/InvoiceView2'))
    },

    // CONSIGNMENT
    {
      exact: true,
      path: PATH_CUSTOMER.general.consignment,
      component: lazy(() => import('src/views/CustomerDashboardGroup/order-consignment/OrderConsignment'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.consignmentDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/order-consignment/details/OrderConsignmentView'))
    },

    // PAYMENT
    {
      exact: true,
      path: PATH_CUSTOMER.general.payment,
      component: lazy(() => import('src/views/CustomerDashboardGroup/payment/Payment'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.paymentDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/payment/PaymentView'))
    },

    // PAYMENT UPDATE
    {
      exact: true,
      path: PATH_CUSTOMER.general.paymentUpdate,
      component: lazy(() => import('src/views/CustomerDashboardGroup/payment-update/PaymentUpdate'))
    },

    // DISTRIBUTOR
    {
      exact: true,
      path: PATH_CUSTOMER.general.distributor,
      component: lazy(() => import('src/views/CustomerDashboardGroup/distributor/Distributor'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.distributorView,
      component: lazy(() => import('src/views/CustomerDashboardGroup/distributor/DistributorView'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.applyDistributor,
      component: lazy(() => import('src/views/CustomerDashboardGroup/distributor/apply/ApplyDistributor'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.distributorDetail.viewAllProducts,
      component: lazy(() => import('src/views/CustomerDashboardGroup/distributor/ViewAllProducts'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.viewApplicationForm,
      component: lazy(() => import('src/views/CustomerDashboardGroup/distributor/view-application-detail/ViewForm'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.editApplicationForm,
      component: lazy(() => import('src/views/CustomerDashboardGroup/distributor/edit-application-detail/EditForm'))
    },

    // REPORT
    {
      exact: true,
      path: PATH_CUSTOMER.general.report,
      component: lazy(() => import('src/views/CustomerDashboardGroup/report/Report'))
    },

    // PROFILE
    {
      exact: true,
      path: PATH_CUSTOMER.general.profile,
      component: lazy(() => import('src/views/CustomerDashboardGroup/profile/Profile'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.editProfile,
      component: lazy(() => import('src/views/CustomerDashboardGroup/profile/EditProfile'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.changeEmail,
      component: lazy(() => import('src/views/CustomerDashboardGroup/profile/ChangeEmail'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.changePassword,
      component: lazy(() => import('src/views/CustomerDashboardGroup/profile/ChangePassword'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.verificationCode,
      component: lazy(() => import('src/views/CustomerDashboardGroup/profile/VerificationCode'))
    },
    // RETAILER APPLICATION FORM
    {
      exact: true,
      path: PATH_CUSTOMER.general.retailerApplicationForm,
      component: lazy(() => import('src/views/CustomerAppReviewGroup/retailer-application-form/RetailerApplicationForm'))
    },
    // EDIT RETAILER APPLICATION FORM FOR APPROVED STATUS
    {
      exact: true,
      path: PATH_CUSTOMER.general.editRetailerApplicationForm.form001,
      component: lazy(() => import('src/views/CustomerDashboardGroup/edit-retailer-application-form/Form001'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.editRetailerApplicationForm.form002,
      component: lazy(() => import('src/views/CustomerDashboardGroup/edit-retailer-application-form/Form002'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.editRetailerApplicationForm.form003,
      component: lazy(() => import('src/views/CustomerDashboardGroup/edit-retailer-application-form/Form003'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.editRetailerApplicationForm.form004,
      component: lazy(() => import('src/views/CustomerDashboardGroup/edit-retailer-application-form/Form004'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.editRetailerApplicationForm.form005,
      component: lazy(() => import('src/views/CustomerDashboardGroup/edit-retailer-application-form/Form005'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.editRetailerApplicationForm.formSummary,
      component: lazy(() => import('src/views/CustomerDashboardGroup/edit-retailer-application-form/FormSummary'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.editRetailerApplicationForm.formAgreement,
      component: lazy(() => import('src/views/CustomerDashboardGroup/edit-retailer-application-form/FormAgreementTermDistributor'))
    },

    // CONSIGNMENT REPORT
    {
      exact: true,
      path: PATH_CUSTOMER.general.consignmentReport,
      component: lazy(() => import('src/views/CustomerDashboardGroup/consignment-report/ConsignmentReport'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.addConsignmentReport.sale,
      component: lazy(() => import('src/views/CustomerDashboardGroup/consignment-report/new/AddConsignmentSale'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.addConsignmentReport.addProduct,
      component: lazy(() => import('src/views/CustomerDashboardGroup/consignment-report/new/ConsignmentAddProduct'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.consignmentReportDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/consignment-report/details/ConsignmentReportView'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.addConsignmentReport.auditStock,
      component: lazy(() => import('src/views/CustomerDashboardGroup/consignment-report/new/AddConsignmentAudit'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.addConsignmentReport.addProductForAudit,
      component: lazy(() => import('src/views/CustomerDashboardGroup/consignment-report/new/audit-stock/ConsignmentAddProduct'))
    },

    // CONSIGNMENT STOCK RETURNABLE
    {
      exact: true,
      path: PATH_CUSTOMER.general.consignmentStockReturnable,
      component: lazy(() => import('src/views/CustomerDashboardGroup/consignment-stock-returnable/ConsignmentStockReturnable'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.consignmentStockReturnableDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/consignment-stock-returnable/details/ConsignmentStockReturnableView'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.addConsignmentStockReturnable.new,
      component: lazy(() => import('src/views/CustomerDashboardGroup/consignment-stock-returnable/new/AddConsignmentStockReturnable'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.addConsignmentStockReturnable.addProduct,
      component: lazy(() => import('src/views/CustomerDashboardGroup/consignment-stock-returnable/new/ConsignmentAddProduct'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.consignmentStockReturnableDetail.edit,
      component: lazy(() => import('src/views/CustomerDashboardGroup/consignment-stock-returnable/details/ConsignmentStockReturnableEdit'))
    },

    // COMPANY ACCOUNT
    {
      exact: true,
      path: PATH_CUSTOMER.general.companyAccount,
      component: lazy(() => import('src/views/CustomerDashboardGroup/company-account/CompanyAccount'))
    },
    // STOCK PRODUCTS
    {
      exact: true,
      path: PATH_CUSTOMER.general.stockProducts,
      component: lazy(() => import('src/views/CustomerDashboardGroup/stock-products/StockProducts'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.stockProductsDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/stock-products/StockProductsView'))
    },

    // CONSIGNMENT SKU ORDER
    {
      exact: true,
      path: PATH_CUSTOMER.general.consignmentSkuOrder,
      component: lazy(() => import('src/views/CustomerDashboardGroup/consignment-sku-order/ConsignmentSkuOrder'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.consignmentSkuOrderDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/consignment-sku-order/detail/ConsignmentSkuOrderView'))
    },

    // STAFF
    {
      exact: true,
      path: PATH_CUSTOMER.general.staff,
      component: lazy(() => import('src/views/CustomerDashboardGroup/staff/Staff'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.addStaff,
      component: lazy(() => import('src/views/CustomerDashboardGroup/staff/new/AddStaff'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.staffDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/staff/details/StaffView'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.staffDetail.edit,
      component: lazy(() => import('src/views/CustomerDashboardGroup/staff/details/StaffEdit'))
    },

    // STAFF PRE-ORDER
    {
      exact: true,
      path: PATH_CUSTOMER.general.staffPreOrder,
      component: lazy(() => import('src/views/CustomerDashboardGroup/staff-preorder/StaffPreOrder'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.staffPreOrderDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/staff-preorder/StaffPreOrderView'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.staffPreOrderDetail.generatePreOrder,
      component: lazy(() => import('src/views/CustomerDashboardGroup/staff-preorder/GeneratePreOrder'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.staffPreOrderDetail.edit,
      component: lazy(() => import('src/views/CustomerDashboardGroup/staff-preorder/StaffPreOrderEdit'))
    },

    // ORDER ISSUE
    {
      exact: true,
      path: PATH_CUSTOMER.general.addOrderIssue,
      component: lazy(() => import('src/views/CustomerDashboardGroup/order-issue/AddOrderIssue'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.orderIssues,
      component: lazy(() => import('src/views/CustomerDashboardGroup/order-issue/OrderIssues'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.orderIssuesDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/order-issue/details/OrderIssueView'))
    },
    
    // OVERPAYMENT
    {
      exact: true,
      path: PATH_CUSTOMER.general.overpayment,
      component: lazy(() => import('src/views/CustomerDashboardGroup/credit-note/CreditNote'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER.general.overpaymentDetail.view,
      component: lazy(() => import('src/views/CustomerDashboardGroup/credit-note/detail/CreditNoteView'))
    }
  ]
}

export default AppCustomerRoutes